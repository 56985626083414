import type { Properties as CampgroundEvents } from '~/types/campground-segment-types';
import { PropertyMetadata as CampgroundEventMetadata } from '~/types/campground-segment-types';

export default async <T extends keyof CampgroundEvents>(
  eventName: T,
  data: CampgroundEvents[T],
  options?: SegmentAnalytics.SegmentOpts & { context?: { [key: string]: any; protocols?: { event_version?: number } } },
  callback?: () => void
) => {
  const $config = useRuntimeConfig().public.segment

  const metadata = CampgroundEventMetadata[eventName]

  if (!data.page_source) {
    data.page_source = getRouteBaseName()
  }

  if (!options) options = {}
  if (!options.context) options.context = {}
  if (!options.context.protocols) options.context.protocols = {}
  options.context.protocols.event_version = metadata.version

  if ($config.consoleLog)
    console.log({ segmentEvent: metadata.name, data, options, callback: callback == null ? null : String(callback) })
  if ($config.enabled) window.analytics?.track(metadata.name, data, options, callback)
  else callback?.()
}
