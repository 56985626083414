export enum AppState {
  Geolocation = 'geolocation',
  AnonymousId = 'anonymous-id',
  RtSessionId = 'rt-session-id',
  Experiments = 'experiments',
  IsBot = 'is-bot',
  BodyScroll = 'body-scroll',
  SearchLocation = 'search-location',
  GeocodeCache = 'geocode-cache'
}
