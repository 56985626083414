export type Properties = {
  'Booking Link Clicked': {
    /**
     * Which provider the link belongs to
     */
    booking_provider: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the product the CTA belongs to
     */
    product_id: string
    /**
     * the url the cta leads to
     */
    url: string
    /**
     * the text label
     */
    value: string
  }
  'Booking Link Clicked [v1]': {
    /**
     * Which provider the link belongs to
     */
    booking_provider: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the product the CTA belongs to
     */
    product_id: string
    /**
     * the url the cta leads to
     */
    url: string
    /**
     * the text label
     */
    value: string
  }
  'Cta Clicked': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the cta id
     */
    cta_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the product the CTA belongs to
     */
    product_id?: string
    /**
     * the url the cta leads to
     */
    url: string
    /**
     * the text label
     */
    value: string
  }
  'Cta Clicked [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the cta id
     */
    cta_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the product the CTA belongs to
     */
    product_id?: string
    /**
     * the url the cta leads to
     */
    url: string
    /**
     * the text label
     */
    value: string
  }
  'Experiment Viewed': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The experiment's human-readable name
     */
    experimentName: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * The variation's human-readable name
     */
    variantName: string
  }
  'Experiment Viewed [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The experiment's human-readable name
     */
    experimentName: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * The variation's human-readable name
     */
    variantName: string
  }
  'Map Closed': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Map Closed [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Map Marker Clicked': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the associated map id
     */
    map_id: string
    /**
     * the marker id
     */
    marker_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    type?: 'Campground' | 'RvRental'
    /**
     * the text label
     */
    value: string
    /**
     * the variant
     */
    variant: 'primary' | 'secondary'
  }
  'Map Marker Clicked [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the associated map id
     */
    map_id: string
    /**
     * the marker id
     */
    marker_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    type?: 'Campground' | 'RvRental'
    /**
     * the text label
     */
    value: string
    /**
     * the variant
     */
    variant: 'primary' | 'secondary'
  }
  'Map Opened': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * the map markers
     */
    markers: {
      /**
       * unique id; typically the id of the associated marker
       */
      marker_id: string
      /**
       * The type of marker
       */
      type: 'Campground' | 'RvRental'
      /**
       * The text shown on the marker
       */
      value?: string
      /**
       * the marker variant
       */
      variant: 'primary' | 'secondary'
    }[]
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Map Opened [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * the map markers
     */
    markers: {
      /**
       * unique id; typically the id of the associated marker
       */
      marker_id: string
      /**
       * The type of marker
       */
      type: 'Campground' | 'RvRental'
      /**
       * The text shown on the marker
       */
      value?: string
      /**
       * the marker variant
       */
      variant: 'primary' | 'secondary'
    }[]
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Map Panned': {
    /**
     * The bounding box
     */
    bounds: {
      northeast: {
        /**
         * The NE Latitude coordinate
         */
        latitude: number
        /**
         * The NE Longitude coordinate
         */
        longitude: number
      }
      southwest: {
        /**
         * The SW Latitude coordinate
         */
        latitude: number
        /**
         * The SW Longitude coordinate
         */
        longitude: number
      }
    }
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Map Panned [v1]': {
    /**
     * The bounding box
     */
    bounds: {
      northeast: {
        /**
         * The NE Latitude coordinate
         */
        latitude: number
        /**
         * The NE Longitude coordinate
         */
        longitude: number
      }
      southwest: {
        /**
         * The SW Latitude coordinate
         */
        latitude: number
        /**
         * The SW Longitude coordinate
         */
        longitude: number
      }
    }
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Map Searched': {
    /**
     * The bounding box
     */
    bounds: {
      northeast: {
        /**
         * The NE Latitude coordinate
         */
        latitude: number
        /**
         * The NE Longitude coordinate
         */
        longitude: number
      }
      southwest: {
        /**
         * The SW Latitude coordinate
         */
        latitude: number
        /**
         * The SW Longitude coordinate
         */
        longitude: number
      }
    }
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * How the search was triggered
     */
    search_type: 'automatic' | 'manual'
  }
  'Map Searched [v1]': {
    /**
     * The bounding box
     */
    bounds: {
      northeast: {
        /**
         * The NE Latitude coordinate
         */
        latitude: number
        /**
         * The NE Longitude coordinate
         */
        longitude: number
      }
      southwest: {
        /**
         * The SW Latitude coordinate
         */
        latitude: number
        /**
         * The SW Longitude coordinate
         */
        longitude: number
      }
    }
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * How the search was triggered
     */
    search_type: 'automatic' | 'manual'
  }
  'Map Zoomed': {
    /**
     * The bounding box
     */
    bounds: {
      northeast: {
        /**
         * The NE Latitude coordinate
         */
        latitude: number
        /**
         * The NE Longitude coordinate
         */
        longitude: number
      }
      southwest: {
        /**
         * The SW Latitude coordinate
         */
        latitude: number
        /**
         * The SW Longitude coordinate
         */
        longitude: number
      }
    }
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * How the search was triggered
     */
    search_type: 'automatic' | 'manual' | 'none'
    /**
     * the zoom level
     */
    zoom_level: number
  }
  'Map Zoomed [v1]': {
    /**
     * The bounding box
     */
    bounds: {
      northeast: {
        /**
         * The NE Latitude coordinate
         */
        latitude: number
        /**
         * The NE Longitude coordinate
         */
        longitude: number
      }
      southwest: {
        /**
         * The SW Latitude coordinate
         */
        latitude: number
        /**
         * The SW Longitude coordinate
         */
        longitude: number
      }
    }
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the map id
     */
    map_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * How the search was triggered
     */
    search_type: 'automatic' | 'manual' | 'none'
    /**
     * the zoom level
     */
    zoom_level: number
  }
  'Menu Closed': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the menu id
     */
    menu_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Menu Closed [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the menu id
     */
    menu_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Menu Opened': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the list of items in the menu
     */
    items: {
      /**
       * the item id
       */
      item_id: string
      /**
       * the text label
       */
      name: string
      /**
       * the position in the list
       */
      position: number
      /**
       * the destination url
       */
      url: string
    }[]
    /**
     * the menu id
     */
    menu_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Menu Opened [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the list of items in the menu
     */
    items: {
      /**
       * the item id
       */
      item_id: string
      /**
       * the text label
       */
      name: string
      /**
       * the position in the list
       */
      position: number
      /**
       * the destination url
       */
      url: string
    }[]
    /**
     * the menu id
     */
    menu_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
  }
  'Menu Option Clicked': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the menu item id
     */
    item_id: string
    /**
     * the associated menu id
     */
    menu_id: string
    /**
     * the text label
     */
    name: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the position in the menu
     */
    position: number
    /**
     * the destination url
     */
    url: string
  }
  'Menu Option Clicked [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the menu item id
     */
    item_id: string
    /**
     * the associated menu id
     */
    menu_id: string
    /**
     * the text label
     */
    name: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the position in the menu
     */
    position: number
    /**
     * the destination url
     */
    url: string
  }
  'Product Clicked': {
    /**
     * Brand associated with the product
     */
    brand?: string
    /**
     * Product category being viewed
     */
    category: string
    /**
     * 	Coupon code associated with a product (for example, MAY_DEALS_3)
     */
    coupon?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Currency of the transaction
     */
    currency?: string
    /**
     * Image url of the product
     */
    image_url?: string
    /**
     * the associated list id
     */
    list_id: string
    /**
     * Name of the product being viewed
     */
    name: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * Position in the product list (ex. 3)
     */
    position?: number
    /**
     * 	Price ($) of the product being viewed
     */
    price?: number
    /**
     * Database id of the product being viewed
     */
    product_id: string
    /**
     * Quantity of a product
     */
    quantity?: number
    /**
     * 	Sku of the product being viewed
     */
    sku?: string
    /**
     * 	URL of the product page
     */
    url: string
    /**
     * 	Total value of the product after quantity
     */
    value?: number
    /**
     * Variant of the product
     */
    variant?: string
  }
  'Product Clicked [v1]': {
    /**
     * Brand associated with the product
     */
    brand?: string
    /**
     * Product category being viewed
     */
    category: string
    /**
     * 	Coupon code associated with a product (for example, MAY_DEALS_3)
     */
    coupon?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Currency of the transaction
     */
    currency?: string
    /**
     * Image url of the product
     */
    image_url?: string
    /**
     * the associated list id
     */
    list_id: string
    /**
     * Name of the product being viewed
     */
    name: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * Position in the product list (ex. 3)
     */
    position?: number
    /**
     * 	Price ($) of the product being viewed
     */
    price?: number
    /**
     * Database id of the product being viewed
     */
    product_id: string
    /**
     * Quantity of a product
     */
    quantity?: number
    /**
     * 	Sku of the product being viewed
     */
    sku?: string
    /**
     * 	URL of the product page
     */
    url: string
    /**
     * 	Total value of the product after quantity
     */
    value?: number
    /**
     * Variant of the product
     */
    variant?: string
  }
  'Product Image Changed': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * what style the images were presented
     */
    display_type: 'carousel' | 'gallery'
    /**
     * how many images in the gallery/carousel
     */
    image_count: number
    /**
     * the image id
     */
    image_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the position of the image
     */
    position: number
    /**
     * the product id
     */
    product_id: string
    /**
     * the url of the image
     */
    url: string
  }
  'Product Image Changed [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * what style the images were presented
     */
    display_type: 'carousel' | 'gallery'
    /**
     * how many images in the gallery/carousel
     */
    image_count: number
    /**
     * the image id
     */
    image_id: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the position of the image
     */
    position: number
    /**
     * the product id
     */
    product_id: string
    /**
     * the url of the image
     */
    url: string
  }
  'Product List Filtered': {
    /**
     * Product category being viewed
     */
    category?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Product filters that the customer is using
     */
    filters: {
      /**
       * Id of the filter type that the customer is using
       */
      type: string
      /**
       * Id of the selection that the customer chose
       */
      value: string
    }[]
    /**
     * Product list being viewed
     */
    list_id?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    pagination?: {
      /**
       * the currently selected page
       */
      current_page: string
      /**
       * how many items per page
       */
      items_per_page: string
    }
    /**
     * Products displayed in the product list
     */
    products: {
      /**
       * 	Brand associated with the product
       */
      brand?: string
      /**
       * 	Product category being viewed
       */
      category: string
      /**
       * 	Coupon code associated with a product (for example, MAY_DEALS_3)
       */
      coupon?: string
      /**
       * 	Image url of the product
       */
      image_url?: string
      /**
       * 	Name of the product being viewed
       */
      name: string
      /**
       * 	Position in the product list (ex. 3)
       */
      position: number
      /**
       * 	Price ($) of the product being viewed
       */
      price?: number
      /**
       * Product id displayed on the list
       */
      product_id: string
      /**
       * Quantity of a product
       */
      quantity?: number
      /**
       * Sku of the product being viewed
       */
      sku?: string
      /**
       * URL of the product page
       */
      url: string
      /**
       * Variant of the product
       */
      variant?: string
    }[]
    sorts?: {
      /**
       * Id of the sort type that the customer is using
       */
      type: string
      /**
       * Id of the selection type the the customer is using (ascending, descending)
       */
      value: string
    }[]
  }
  'Product List Filtered [v1]': {
    /**
     * Product category being viewed
     */
    category?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Product filters that the customer is using
     */
    filters: {
      /**
       * Id of the filter type that the customer is using
       */
      type: string
      /**
       * Id of the selection that the customer chose
       */
      value: string
    }[]
    /**
     * Product list being viewed
     */
    list_id?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    pagination?: {
      /**
       * the currently selected page
       */
      current_page: string
      /**
       * how many items per page
       */
      items_per_page: string
    }
    /**
     * Products displayed in the product list
     */
    products: {
      /**
       * 	Brand associated with the product
       */
      brand?: string
      /**
       * 	Product category being viewed
       */
      category: string
      /**
       * 	Coupon code associated with a product (for example, MAY_DEALS_3)
       */
      coupon?: string
      /**
       * 	Image url of the product
       */
      image_url?: string
      /**
       * 	Name of the product being viewed
       */
      name: string
      /**
       * 	Position in the product list (ex. 3)
       */
      position: number
      /**
       * 	Price ($) of the product being viewed
       */
      price?: number
      /**
       * Product id displayed on the list
       */
      product_id: string
      /**
       * Quantity of a product
       */
      quantity?: number
      /**
       * Sku of the product being viewed
       */
      sku?: string
      /**
       * URL of the product page
       */
      url: string
      /**
       * Variant of the product
       */
      variant?: string
    }[]
    sorts?: {
      /**
       * Id of the sort type that the customer is using
       */
      type: string
      /**
       * Id of the selection type the the customer is using (ascending, descending)
       */
      value: string
    }[]
  }
  'Product List Viewed': {
    /**
     * Product category being viewed
     */
    category?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Product list being viewed
     */
    list_id?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    pagination?: {
      /**
       * the currently selected page
       */
      current_page: number
      /**
       * how many items per page
       */
      items_per_page: number
    }
    /**
     * Products displayed in the product list
     */
    products: {
      /**
       * 	Brand associated with the product
       */
      brand?: string
      /**
       * 	Product category being viewed
       */
      category: string
      /**
       * 	Coupon code associated with a product (for example, MAY_DEALS_3)
       */
      coupon?: string
      /**
       * Product currency
       */
      currency?: string
      /**
       * 	Image url of the product
       */
      image_url?: string
      /**
       * 	Name of the product being viewed
       */
      name: string
      /**
       * 	Position in the product list (ex. 3)
       */
      position: number
      /**
       * 	Price ($) of the product being viewed
       */
      price?: number
      /**
       * Product id displayed on the list
       */
      product_id: string
      /**
       * Quantity of a product
       */
      quantity?: number
      /**
       * Sku of the product being viewed
       */
      sku?: string
      /**
       * URL of the product page
       */
      url: string
      /**
       * Variant of the product
       */
      variant?: string
    }[]
  }
  'Product List Viewed [v1]': {
    /**
     * Product category being viewed
     */
    category?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Product list being viewed
     */
    list_id?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    pagination?: {
      /**
       * the currently selected page
       */
      current_page: number
      /**
       * how many items per page
       */
      items_per_page: number
    }
    /**
     * Products displayed in the product list
     */
    products: {
      /**
       * 	Brand associated with the product
       */
      brand?: string
      /**
       * 	Product category being viewed
       */
      category: string
      /**
       * 	Coupon code associated with a product (for example, MAY_DEALS_3)
       */
      coupon?: string
      /**
       * Product currency
       */
      currency?: string
      /**
       * 	Image url of the product
       */
      image_url?: string
      /**
       * 	Name of the product being viewed
       */
      name: string
      /**
       * 	Position in the product list (ex. 3)
       */
      position: number
      /**
       * 	Price ($) of the product being viewed
       */
      price?: number
      /**
       * Product id displayed on the list
       */
      product_id: string
      /**
       * Quantity of a product
       */
      quantity?: number
      /**
       * Sku of the product being viewed
       */
      sku?: string
      /**
       * URL of the product page
       */
      url: string
      /**
       * Variant of the product
       */
      variant?: string
    }[]
  }
  'Product Viewed': {
    /**
     * Brand associated with the product
     */
    brand?: string
    /**
     * Product category being viewed
     */
    category: string
    /**
     * 	Coupon code associated with a product (for example, MAY_DEALS_3)
     */
    coupon?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Currency of the transaction
     */
    currency?: string
    /**
     * Image url of the product
     */
    image_url?: string
    /**
     * Name of the product being viewed
     */
    name: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * Position in the product list (ex. 3)
     */
    position?: number
    /**
     * 	Price ($) of the product being viewed
     */
    price?: number
    /**
     * Database id of the product being viewed
     */
    product_id: string
    /**
     * Quantity of a product
     */
    quantity?: number
    /**
     * 	Sku of the product being viewed
     */
    sku?: string
    /**
     * 	URL of the product page
     */
    url: string
    /**
     * 	Total value of the product after quantity
     */
    value?: number
    /**
     * Variant of the product
     */
    variant?: string
  }
  'Product Viewed [v1]': {
    /**
     * Brand associated with the product
     */
    brand?: string
    /**
     * Product category being viewed
     */
    category: string
    /**
     * 	Coupon code associated with a product (for example, MAY_DEALS_3)
     */
    coupon?: string
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * Currency of the transaction
     */
    currency?: string
    /**
     * Image url of the product
     */
    image_url?: string
    /**
     * Name of the product being viewed
     */
    name: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * Position in the product list (ex. 3)
     */
    position?: number
    /**
     * 	Price ($) of the product being viewed
     */
    price?: number
    /**
     * Database id of the product being viewed
     */
    product_id: string
    /**
     * Quantity of a product
     */
    quantity?: number
    /**
     * 	Sku of the product being viewed
     */
    sku?: string
    /**
     * 	URL of the product page
     */
    url: string
    /**
     * 	Total value of the product after quantity
     */
    value?: number
    /**
     * Variant of the product
     */
    variant?: string
  }
  'Products Searched': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * Query the user searched with
     */
    query: string | null
  }
  'Products Searched [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * Query the user searched with
     */
    query: string | null
  }
  'Promotional Unit Clicked': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the position the promotional unit was in a list
     */
    position?: number
    /**
     * the promotional unit id
     */
    promotional_unit_id: string
    /**
     * the url the unit leads to
     */
    url: string
    /**
     * the text label/description of the unit
     */
    value: string
    /**
     * the variant id of the unit
     */
    variant_id: 'card'
  }
  'Promotional Unit Clicked [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the position the promotional unit was in a list
     */
    position?: number
    /**
     * the promotional unit id
     */
    promotional_unit_id: string
    /**
     * the url the unit leads to
     */
    url: string
    /**
     * the text label/description of the unit
     */
    value: string
    /**
     * the variant id of the unit
     */
    variant_id: 'card'
  }
  'Search Input Changed': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the available options the user had to pick from
     */
    options?: string[]
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the search query the user put in
     */
    query: string
    /**
     * the option the user picked
     */
    selected_option: string
  }
  'Search Input Changed [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the available options the user had to pick from
     */
    options?: string[]
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the search query the user put in
     */
    query: string
    /**
     * the option the user picked
     */
    selected_option: string
  }
  'Shelf Navigation': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The direction the user clicked
     */
    direction: 'left' | 'right'
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the shelf id
     */
    shelf_id: string
    /**
     * how many items were navigated
     */
    step: number
    /**
     * which item it navigated to
     */
    to?: number
  }
  'Shelf Navigation [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * The direction the user clicked
     */
    direction: 'left' | 'right'
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the shelf id
     */
    shelf_id: string
    /**
     * how many items were navigated
     */
    step: number
    /**
     * which item it navigated to
     */
    to?: number
  }
  'Shelf Scrolled': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the starting pixel position measured from the left side
     */
    from: number
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the shelf id
     */
    shelf_id: string
    /**
     * the ending pixel position measured from the left side
     */
    to: number
  }
  'Shelf Scrolled [v1]': {
    /**
     * The action that caused the action
     */
    cta?: string
    /**
     * the starting pixel position measured from the left side
     */
    from: number
    /**
     * The type of page that it came from
     */
    page_source?: string
    /**
     * the shelf id
     */
    shelf_id: string
    /**
     * the ending pixel position measured from the left side
     */
    to: number
  }
}

export const PropertyMetadata = {
  'Booking Link Clicked': {
    name: 'Booking Link Clicked',
    version: 1
  },
  'Booking Link Clicked [v1]': {
    name: 'Booking Link Clicked',
    version: 1
  },
  'Cta Clicked': {
    name: 'Cta Clicked',
    version: 1
  },
  'Cta Clicked [v1]': {
    name: 'Cta Clicked',
    version: 1
  },
  'Experiment Viewed': {
    name: 'Experiment Viewed',
    version: 1
  },
  'Experiment Viewed [v1]': {
    name: 'Experiment Viewed',
    version: 1
  },
  'Map Closed': {
    name: 'Map Closed',
    version: 1
  },
  'Map Closed [v1]': {
    name: 'Map Closed',
    version: 1
  },
  'Map Marker Clicked': {
    name: 'Map Marker Clicked',
    version: 1
  },
  'Map Marker Clicked [v1]': {
    name: 'Map Marker Clicked',
    version: 1
  },
  'Map Opened': {
    name: 'Map Opened',
    version: 1
  },
  'Map Opened [v1]': {
    name: 'Map Opened',
    version: 1
  },
  'Map Panned': {
    name: 'Map Panned',
    version: 1
  },
  'Map Panned [v1]': {
    name: 'Map Panned',
    version: 1
  },
  'Map Searched': {
    name: 'Map Searched',
    version: 1
  },
  'Map Searched [v1]': {
    name: 'Map Searched',
    version: 1
  },
  'Map Zoomed': {
    name: 'Map Zoomed',
    version: 1
  },
  'Map Zoomed [v1]': {
    name: 'Map Zoomed',
    version: 1
  },
  'Menu Closed': {
    name: 'Menu Closed',
    version: 1
  },
  'Menu Closed [v1]': {
    name: 'Menu Closed',
    version: 1
  },
  'Menu Opened': {
    name: 'Menu Opened',
    version: 1
  },
  'Menu Opened [v1]': {
    name: 'Menu Opened',
    version: 1
  },
  'Menu Option Clicked': {
    name: 'Menu Option Clicked',
    version: 1
  },
  'Menu Option Clicked [v1]': {
    name: 'Menu Option Clicked',
    version: 1
  },
  'Product Clicked': {
    name: 'Product Clicked',
    version: 1
  },
  'Product Clicked [v1]': {
    name: 'Product Clicked',
    version: 1
  },
  'Product Image Changed': {
    name: 'Product Image Changed',
    version: 1
  },
  'Product Image Changed [v1]': {
    name: 'Product Image Changed',
    version: 1
  },
  'Product List Filtered': {
    name: 'Product List Filtered',
    version: 1
  },
  'Product List Filtered [v1]': {
    name: 'Product List Filtered',
    version: 1
  },
  'Product List Viewed': {
    name: 'Product List Viewed',
    version: 1
  },
  'Product List Viewed [v1]': {
    name: 'Product List Viewed',
    version: 1
  },
  'Product Viewed': {
    name: 'Product Viewed',
    version: 1
  },
  'Product Viewed [v1]': {
    name: 'Product Viewed',
    version: 1
  },
  'Products Searched': {
    name: 'Products Searched',
    version: 1
  },
  'Products Searched [v1]': {
    name: 'Products Searched',
    version: 1
  },
  'Promotional Unit Clicked': {
    name: 'Promotional Unit Clicked',
    version: 1
  },
  'Promotional Unit Clicked [v1]': {
    name: 'Promotional Unit Clicked',
    version: 1
  },
  'Search Input Changed': {
    name: 'Search Input Changed',
    version: 1
  },
  'Search Input Changed [v1]': {
    name: 'Search Input Changed',
    version: 1
  },
  'Shelf Navigation': {
    name: 'Shelf Navigation',
    version: 1
  },
  'Shelf Navigation [v1]': {
    name: 'Shelf Navigation',
    version: 1
  },
  'Shelf Scrolled': {
    name: 'Shelf Scrolled',
    version: 1
  },
  'Shelf Scrolled [v1]': {
    name: 'Shelf Scrolled',
    version: 1
  }
}
